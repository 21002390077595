import { GeokTheme } from '@app/configs/geokTheme';

export interface GeoThemes {
  default: GeokTheme;
  spl: GeokTheme;
}

export const THEMES: GeoThemes = {
  default: {
    familyFont: 'Poppins, Tajawal',
    buttonBorderRadius: '24px',
    iconButtonBorderRadius: '8px',

    lightThemeColor: '#eff7ff',
    screenBackgroundColor: '#f3f4f8',
    cardBackgroundColor: '#eff7ff',

    primaryTextColor: '#03123f',
    secondaryTextColor: '#317ffe',

    navigationGradientOne: '#f6f8f9',
    navigationGradientTwo: '#f6f8f9',

    navigationTextColor: '#03123f',
    navigationTextActiveColor: '#317ffe',
    navigationIconActiveColor: '#03123f',
    navigationIconActiveBgColor: '#d1e1fa',

    primaryBgButton: '#03123F',
    primaryBgButtonHover: '#120068',
    primaryTextButton: '#f7f6f9',

    secondaryBgButton: '#dbeaf9',
    secondaryBgButtonHover: '#b5bedf',
    secondaryTextButton: '#17006a',

    outlinedBgButtonHover: '#eff7ff',
    outlinedTextButton: '#17006a',

    textBgButtonHover: '#dbeaf9',
    textTextButton: '#17006a',
    smallLogo: '',
    backgroundImage: '',
    mainLogo: '',
  } as GeokTheme,
  spl: {
    familyFont: 'Tahoma',
    buttonBorderRadius: '0',
    iconButtonBorderRadius: '0',
    lightThemeColor: '#ccf4f9',

    primaryTextColor: '#153c3f',
    secondaryTextColor: '#146e82',
    screenBackgroundColor: '',
    cardBackgroundColor: '#ccf4f9',

    navigationGradientOne: '#00c8e1',
    navigationGradientTwo: '#12abbe',

    navigationTextColor: '#153c3f',
    navigationTextActiveColor: '#146e82',

    navigationIconActiveColor: '#05001a',
    navigationIconActiveBgColor: '#d0f0ef',

    primaryBgButton: '#99e9f3',
    primaryBgButtonHover: '#66deed',
    primaryTextButton: '#153c3f',

    secondaryBgButton: '#a1c5cd',
    secondaryBgButtonHover: '#72a8b4',
    secondaryTextButton: '#153c3f',

    outlinedBgButtonHover: '#d0e2e6',
    outlinedTextButton: '#153c3f',

    textBgButtonHover: '#d0e2e6',
    textTextButton: '#153c3f',
    smallLogo: '',
    backgroundImage: '',
    mainLogo: '',
  } as GeokTheme,
};
